import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

import HousePic1 from "../images/house/1333_Bayberry_View_Ln.jpg"
import HousePic2 from "../images/house/3999_Cavestri_Cir.jpg"
import HousePic3 from "../images/house/2357_Fairfield_Ct.webp"
import HousePic4 from "../images/house/7651_Fairoaks_Dr.webp"
import HousePic5 from "../images/house/621_Kirkstone_Ct.webp"
import HousePic6 from "../images/house/231_Balceta_Ct.webp"


const images = [
    HousePic1,
    HousePic2,
    HousePic3,
    HousePic4,
    HousePic5,
    HousePic6,
];

export default class Gallery extends Component {

    state = {
        photoIndex : 0,
        isOpen : false,
    }

    render() {
        const { photoIndex, isOpen } = this.state;
        return (
            <div className="gallery">
                <ul>
                    <li>
                        <a onClick={() => this.setState({ isOpen: true })}>
                            <img src={HousePic1} alt=""/>
                        </a>
                    </li>
                    <li>
                        <a onClick={() => this.setState({ isOpen: true })}>
                            <img src={HousePic2} alt=""/>
                        </a>
                    </li>
                    <li>
                        <a onClick={() => this.setState({ isOpen: true })}>
                            <img src={HousePic3} alt=""/>
                        </a>
                    </li>
                    <li>
                        <a onClick={() => this.setState({ isOpen: true })}>
                            <img src={HousePic4} alt=""/>
                        </a>
                    </li>
                    <li>
                        <a onClick={() => this.setState({ isOpen: true })}>
                            <img src={HousePic5} alt=""/>
                        </a>
                    </li>
                    <li>
                        <a onClick={() => this.setState({ isOpen: true })}>
                            <img src={HousePic6} alt=""/>
                        </a>
                    </li>
                </ul>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % images.length,
                        })
                        }
                    />
                )}
            </div>
        )
    }
}
