import React from "react"
import { Link } from "gatsby"
import WechatImg from "../../images/wechat.jpg"
import lang from "../lang"
import Badge from 'react-bootstrap/Badge'

const Navs = ({ navStyle }) => {
  return (
    <ul className={`navbar-nav ${navStyle}`}>
      <li className="nav-item">
        <Link
          to="/"
          className="nav-link"
          activeClassName="active"
          partiallyActive={true}
        >
          {lang === "EN" ? "Home": "首页"}
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="/sell"
          className="nav-link"
          activeClassName="active"
          partiallyActive={true}
        >
          {lang === "EN" ? "Sell": "我要卖房"} <Badge variant="danger">Hot</Badge>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="/buy"
          className="nav-link"
          activeClassName="active"
          partiallyActive={true}
        >
          {lang === "EN" ? "Buy": "我要买房"} <Badge variant="danger">Hot</Badge>
        </Link>
      </li>
      <li className="nav-item megadrop">
        <Link
          to="/cities"
          className="nav-link"
          activeClassName="active"
          partiallyActive={true}
        >
          {lang === "EN" ? "Served Area": "湾区城市"} <Badge variant="danger">Hot</Badge>
        </Link>
        <div className="megadrop-down">
          <div className="container">
            <div className="dropdown w-100">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Mountain View">
                        Mountain View -- 从谷歌诞生地到硅谷核心区 <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/San Jose">
                        San Jose -- 硅谷的商业和研发中心 <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Cupertino">
                        Cupertino -- 硅谷的心脏，中国城 <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Sunnyvale">
                        Sunnyvale -- 高素质的居民，干净明亮的街区，时尚多样的配套 <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Fremont">
                        Fremont -- 整个湾区的中点，一流学区，全美生活最幸福的地方 <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Milpitas">
                        Milpitas -- 越来越年轻化的城市
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Palo Alto">
                      Palo Alto -- 硅谷核心城市，加州最好的学区 <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Newark">
                      Newark -- 性价比高，交通方便，年轻工程师的首选安家之地
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Pleasanton">
                      Pleasanton -- 旧金山东湾最佳学区，明星城市，华人眼中的“幸福屯” <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Dublin">
                      Dublin -- 社区优美，发展快速，文化多元的明星之城 <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/San Ramon">
                        San Ramon -- 东湾最安全的好学区，加州抚养孩子最安全的城市 <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Danville">
                        Danville -- 加州传统的好学区、富人区，最适合家庭居住城镇 <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Saratoga">
                      Saratoga -- 悠闲安静，临近硅谷，硅谷精英的后花园 <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Redwood City">
                      Redwood City -- 百万美元的毫宅，无敌海景
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Castro Valley">
                      Castro Valley -- 地理位置佳、可负担的价位，周边环境风景宜人
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Foster City">
                      Foster City -- 风景旖旎的岛居之城，华人置业移民的首选城市之一
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/San Mateo">
                      San Mateo -- 生活便利、交通方便、环境优美
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Livermore">
                      Livermore -- 地广人少，风景优美，交通方便 <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Campbell">
                      Campbell -- 迅猛成长，拥有良好社区，硅谷新宠 <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Tracy">
                      Tracy -- 新兴开发建设的大型居民社区，充满活力全新社区 <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Alameda">
                      Alameda -- 与世隔绝的滨水城市，又不失生活的便利
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Walnut Creek">
                      Walnut Creek -- 美丽富饶，以白人为主的雅致小城
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Los Gatos">
                      Los Gatos -- 深受欢迎的富人区，环境优美学区优质 <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12">
                  <ul>
                    <li>
                      <Link to="/Los Altos">
                      Los Altos -- 最昂贵和最富有的城市之一，硅谷豪宅区 <Badge variant="danger">Hot</Badge>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </li>
      {/*
      <li className="nav-item drop">
        <Link to="/" className="nav-link">
          Features
        </Link>
        <ul className="drop-down">
          <li>
            <a href="#">
              Header
              <FaAngleRight />
            </a>
            <ul className="drop-down level3">
              <li>
                <Link to="/header1">Header 1</Link>
              </li>
              <li>
                <Link to="/header2">Header 2</Link>
              </li>
              <li>
                <Link to="/header3">Header 3</Link>
              </li>
              <li>
                <Link to="/header4">Header 4</Link>
              </li>
              <li>
                <Link to="/header5">Header 5</Link>
              </li>
              <li>
                <Link to="/header6">Header 6</Link>
              </li>
              <li>
                <Link to="/header7">Header 7</Link>
              </li>
              <li>
                <Link to="/header8">Header 8</Link>
              </li>
            </ul>
          </li>
          <li>
            <a href="#">
              Slideshow
              <FaAngleRight />
            </a>
            <ul className="drop-down level3">
              <li>
                <Link to="/sliderbootstrap" activeClassName="active">
                  Bootstrap Slider
                </Link>
              </li>
              <li>
                <Link to="/banner1" activeClassName="active">
                  Banner 1
                </Link>
              </li>
              <li>
                <Link to="/banner2" activeClassName="active">
                  Banner 2
                </Link>
              </li>
              <li>
                <Link to="/banner3" activeClassName="active">
                  Banner 3
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <a href="#">
              Footer
              <FaAngleRight />
            </a>
            <ul className="drop-down level3">
              <li>
                <Link to="/footer1" activeClassName="active">
                  Footer 1
                </Link>
              </li>
              <li>
                <Link to="/footer2" activeClassName="active">
                  Footer 2
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      */}
      <li className="nav-item drop">
        <Link
          to="/blogs"
          className="nav-link"
          activeClassName="active"
          partiallyActive={true}
        >
          {lang === "EN" ? "Blog": "买房攻略"}
        </Link>
        <ul className="drop-down" aria-labelledby="navbarDropdown">
          <li>
            <Link to="/how-to-buy-house-in-bay-area" activeClassName="active">
            地产经纪Jing Xue给你讲旧金山湾区首次买房全攻略！
            </Link>
          </li>
          <li>
            <Link to="/supplemental-property-tax" activeClassName="active">
            什么是 Supplemental Property Tax
            </Link>
          </li>
          <li>
            <Link to="/equity-right" activeClassName="active">
            房子下周就CLOSE了，产权持有方式到底该怎么选？
            </Link>
          </li>
          <li>
            <Link to="/superfund" activeClassName="active">
            说说购房过程中的那些坑—Superfund
            </Link>
          </li>
          <li>
            <Link to="/buy-or-sell" activeClassName="active">
            想换房，先买再卖？还是先卖再买？来看我们怎么做到买卖同时进行！
            </Link>
          </li>
          <li>
            <Link to="/Prop19" activeClassName="active">
            Prop 19法案通过了，对我们湾区房市会有什么影响？
            </Link>
          </li>
          <li>
            <Link to="/bay-area-great-schoool-city" activeClassName="active">
            湾区具有最好学区的6个城市
            </Link>
          </li>
          <li>
            <Link to="/bay-area-new-home" activeClassName="active">
            美国买新房必读 9件该知道的事
            </Link>
          </li>
          <li>
            <Link to="/tri-valley" activeClassName="active">
            选择定居湾区东湾三谷，追求享受美好人生！
            </Link>
          </li>
          <li>
            <Link to="/bay-area-public-school" activeClassName="active">
            湾区最佳公立高中排名出炉,告诉你去哪买房最值!
            </Link>
          </li>
          <li>
            <Link to="/bay-area-top-city" activeClassName="active">
            湾区最适合家庭居住的10个城市!硅谷工程师必看！
            </Link>
          </li>
          <li>
            <Link to="/foundation-issue" activeClassName="active">
            如何判断地基是否下沉,地基下沉的表现
            </Link>
          </li>
          <li>
            <Link to="/noise" activeClassName="active">
            湾区买房一定要知道的噪音问题！
            </Link>
          </li>
          <li>
            <Link to="/east-palo-alto-crime" activeClassName="active">
            East Palo Alto -- 从犯罪之都变身硅谷房产热门城市
            </Link>
          </li>
          <li>
            <Link to="/palo-alto-estate" activeClassName="active">
            Jing Xue湾区房产地图 -- Palo Alto
            </Link>
          </li>
          <li>
            <Link to="/fremont-estate" activeClassName="active">
            Jing Xue湾区房产地图 -- Fremont
            </Link>
          </li>
          <li>
            <Link to="/pleasanton-estate" activeClassName="active">
            Jing Xue湾区房产地图 -- Pleasanton
            </Link>
          </li>
          <li>
            <Link to="/san-ramon-estate" activeClassName="active">
            Jing Xue湾区房产地图 -- San Ramon
            </Link>
          </li>
          <li>
            <Link to="/milpitas-estate" activeClassName="active">
            Jing Xue湾区房产地图 -- Milpitas
            </Link>
          </li>
          <li>
            <Link to="/warm-springs-estate" activeClassName="active">
            Jing Xue湾区房产地图 -- Warm Springs
            </Link>
          </li>
          <li>
            <Link to="/castro-valley-estate" activeClassName="active">
            Jing Xue湾区房产地图 -- Castro Valley
            </Link>
          </li>
        </ul>
      </li>
      <li className="nav-item">
        <Link
          to="/schools"
          className="nav-link"
          activeClassName="active"
          partiallyActive={true}
        >
          湾区学区
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="/reviews"
          className="nav-link"
          activeClassName="active"
        >
          {lang === "EN" ? "Reviews": "客户评价"}
        </Link>
      </li>
      <li className="nav-item megadrop">
        <a
          className="nav-link"
          href="/"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {lang === "EN" ? "Contact": "联系我们"}
        </a>
        <div className="megadrop-down">
          <div className="container">
            <div className="dropdown w-100">
              <div className="row">
                <div className="col-md-4 col-sm-4">
                  <div id="map" style={{ height: "300px" }}>
                    <iframe
                      title="联系地址"
                      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDr7gRsDPCn7v3tZ1jlD1_oOyiu9dg9M6s&q=Bay+Area+California"
                      width="100%"
                      height="100%"
                      style={{ border: "0" }}
                    ></iframe>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div id="qrcode" style={{ height: "300px" }}>
                    <img src={WechatImg} alt="" style={{maxHeight:300, width:'auto'}} />
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="menu-contact">
                    <div className="feature-2">
                      <div className="media">
                        <div className="media-left">
                          <div className="feature-icon text-center">
                            <i className="icon-map"></i>
                          </div>
                        </div>
                        <div className="media-body">
                          <a href="/">
                            <h3 className="media-heading">ADDRESS</h3>
                          </a>
                          <p>
                          5980 Stoneridge Dr Ste 122, Pleasanton, CA
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="feature-2">
                      <div className="media">
                        <div className="media-left">
                          <div className="feature-icon text-center">
                            <i className="icon-envelope"></i>
                          </div>
                        </div>
                        <div className="media-body">
                          <a href="/">
                            <h3 className="media-heading">E-mail</h3>
                          </a>
                          <p>xuesimone@gmail.com</p>
                        </div>
                      </div>
                    </div>
                    <div className="feature-2" style={{ marginBottom: "0" }}>
                      <div className="media">
                        <div className="media-left">
                          <div className="feature-icon text-center">
                            <i className="icon-phone"></i>
                          </div>
                        </div>
                        <div className="media-body">
                          <a href="/">
                            <h3 className="media-heading">Phone</h3>
                          </a>
                          <p>+1-(650)-542-1628</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  )
}

export default Navs
