import React from 'react'
import TopHeader from './TopHeader'
import Navbar from '../Navbar/Navbar'
import {Helmet} from "react-helmet";
import {isMobile} from 'react-device-detect';

const HeaderStyle1 = ({topHeaderStyle, navbarStyle}) => {
    return (
        <>
            {!isMobile && <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Jing Xue | 湾区置业专家 湾区Top华人房产经纪 Zillow全5星好评 | 熟悉南湾、东湾、三谷市场 | 湾区agent推荐 | Remax Accord 排名第一 经纪人 | 近12个月成交量超1亿美金</title>
                    <meta name="description" content="一支秉持诚信、负责、专业的湾区TOP 1% 房产经纪人团队，熟悉南湾、东湾、三谷市场，全面考虑客户利益，帮客户选择最适合的房子，专业团队的一条龙服务给客户带来轻松高效的房产交易体验！团队提供房屋贷款、房屋装修、staging、清洁等服务" />
                    <meta name="Keywords" content="湾区,华人房产经纪,南湾,东湾,三谷,地产经纪,pleasanton,fremont,cupertino,san jose,mountain view,dublin,palo alto,melo park,livermore,dublin,danville"></meta>
                    <meta name="author" content="Jing Xue" />
                    <link rel="canonical" href="https://www.jingxuehomes.com" />
                </Helmet>
            </div>}
            <TopHeader topHeaderStyle={topHeaderStyle} />
            <Navbar navbarStyle={navbarStyle} />
        </>
    )
}

export default HeaderStyle1
