import React from 'react'
import {FaFacebookF,FaLinkedinIn,FaPinterest,FaYoutube,FaTwitter,FaGooglePlusG,FaPhoneVolume,FaMediumM,FaFacebookMessenger,FaStar,FaThumbsUp} from "react-icons/fa"
import {isMobile} from 'react-device-detect';

const TopHeader = ({topHeaderStyle}) => {
    return (
        <section className={`top-header ${topHeaderStyle ? topHeaderStyle : ""}`}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-sm-6">
                        <div className="float-left">
                            <div className="top-phone mr10"><FaFacebookMessenger/> 微信: TopAgentJingXue</div>
                            <div className="top-phone mr10"><FaPhoneVolume/>  +1 650 542 1628</div>
                            <div className="top-address"><FaMediumM/> Realtor® DRE#02066062</div>
                        </div>
                    </div>
                    {!isMobile && <div className="col-md-4 col-sm-6">
                        <div className="social-top">
                            <ul className="top-social">
                                <li><a href="https://www.facebook.com/jing.xue.75491" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a></li>
                                <li><a href="https://www.google.com/search?q=jing+xue+realtor&#lrd=0x808feb5994a4c681:0xdb249dd99ecaacc9,1,,," target="_blank" rel="noopener noreferrer"><FaGooglePlusG/></a></li>
                                <li><a href="https://www.zillow.com/profile/Jing-Xue/" target="_blank" rel="noopener noreferrer"><FaStar/></a></li>
                                <li><a href="/" target="_blank" rel="noopener noreferrer"><FaTwitter/></a></li>
                                <li><a href="https://www.pinterest.com/xuesimone/" target="_blank" rel="noopener noreferrer"><FaPinterest/></a></li>
                                <li><a href="https://www.linkedin.com/in/jing-xue-5b616a86/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn/></a></li>
                                <li><a href="https://www.youtube.com/channel/UCTNq0iPCZDG6I9pxngpK7EQ" target="_blank" rel="noopener noreferrer"><FaYoutube/></a></li>
                                <li><a href="https://www.dealmoon.com/local-store/45159" target="_blank" rel="noopener noreferrer"><FaThumbsUp/></a></li>
                            </ul>
                        </div>
                    </div>}
                </div>
            </div>
        </section>
    )
}

export default TopHeader
