import React from "react"
import Gallery from "../Gallery"
import {FaFacebookF,FaPinterest,FaYoutube,FaGooglePlusG,FaStar,FaThumbsUp} from "react-icons/fa"
import lang from "../lang"
import { Link } from "gatsby"

const FooterStyle1 = () => {
  return (
    <section
      className="footer-section pad-t80 parallax"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-title">
              <h3>About Us</h3>
            </div>
            <div className="footer-text">
              <p>
                Realtor® DRE#02066062 |湾区房产经纪
              </p>
              <p>
                Specialties:Buyer's Agent,Listing Agent,Relocation,Property Management,Landlord,Vacation / Short-term Rental
              </p>
              <p>
              一位真正秉持"诚信、负责、专业" 的湾区全职房产经纪人！熟悉南湾、东湾、三谷市场，手把手高效与您完成房产买卖服务，为您带来轻松高效的购房体验! 专业国际公司市场营销背景出身，精通英文、普通话。   
              </p>
            </div>
          </div>
          <div className="col-md-2">
            <div className="footer-title">
              <h3>Service List</h3>
            </div>
            <div className="footer-list">
              <ul>
                <li>
                  <Link to='sell'>
                    <i className="fa fa-long-arrow-right"></i>{lang === "EN" ? "I Want Sell": "我要卖房"}
                  </Link>
                </li>
                <li>
                  <Link to='buy'>
                    <i className="fa fa-long-arrow-right"></i>{lang === "EN" ? "I Want Buy": "我要买房"}
                  </Link>
                </li>
                <li>
                  <Link to='how-to-buy-house-in-bay-area'>
                    <i className="fa fa-long-arrow-right"></i>{lang === "EN" ? "How to Buy a House": "湾区买房攻略"}
                  </Link>
                </li>
                <li>
                  <Link to='reviews'>
                    <i className="fa fa-long-arrow-right"></i>{lang === "EN" ? "Customer Reviews": "客户评价"}
                  </Link>
                </li>
                <li>
                  <Link to='#'>
                    <i className="fa fa-long-arrow-right"></i>{lang === "EN" ? "Contact Us": "联系我们"}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer-title">
              <h3>Our Gallery</h3>
            </div>
            <Gallery/>
          </div>
          <div className="col-md-3">
            <div className="footer-title">
              <h3>Subscribe</h3>
            </div>
            <p>订阅我们，获取最新湾区房产市场信息.</p>
            <form>
              <div className="form-group footer-subscribe">
                <input
                  type="email"
                  className="form-control"
                  id="Email1"
                  placeholder="Subscribe with us"
                />
                <button type="submit" className="btn btn-default">
                  Join
                </button>
              </div>
            </form>
            <div className="social-top">
              <ul className="top-social">
                <li><a href="https://www.facebook.com/jing.xue.75491"><span><FaFacebookF/></span></a></li>
                <li><a href="https://www.google.com/search?q=jing+xue+realtor&#lrd=0x808feb5994a4c681:0xdb249dd99ecaacc9,1,,,"><span><FaGooglePlusG/></span></a></li>
                <li><a href="https://www.zillow.com/profile/Jing-Xue/"><span><FaStar/></span></a></li>
                <li><a href="https://www.pinterest.com/xuesimone/"><span><FaPinterest/></span></a></li>
                <li><a href="https://www.dealmoon.com/local-store/45159"><span><FaThumbsUp/></span></a></li>
                <li><a href="https://www.youtube.com/channel/UCTNq0iPCZDG6I9pxngpK7EQ"><span><FaYoutube/></span></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row copyright">
          <div className="col-md-6">
            <div className="copyright-text">
              <p>
                N B © All Rights Reserved. Developed by <a href="/">Jing Xue</a>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="footer-menu">
              <ul>
                <li>
                  <a href="/blogs">FAQs</a>
                </li>
                <li>
                  <a href="/">Privacy Policy</a>
                </li>
                <li>
                  <a href="/">Terms of Use</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FooterStyle1
